@use '../../styles' as *;

.container {
  @extend %flex-center;
  gap: 0.25rem;
  letter-spacing: 2px;
  margin-bottom: -0.25rem;
  margin-left: 0.25rem;
}

.loading-dots {
  display: inline-block;
  @include pulsingDotsAnimation;
}
