@use '../../styles' as *;

.subclass-buttons {
  text-align: center;
  margin-bottom: 1rem;
  padding: $padding-component;
  background: $color-component-bg;
  border-radius: 0.5rem;
  @include default_border(dashed);

  &__title {
    flex: 1;
    font-size: font-size('sm');
    font-weight: 600;
    margin-block-end: 0.75rem;
    margin-block-start: 0;
  }

  &__buttons {
    @extend %flex-center;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
}
