%flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

%flex-column {
  display: flex;
  flex-direction: column;
}

%flex-start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

%flex-end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
