@use '../../../styles' as *;

.error-message {
  @extend %flex-center;
  flex-direction: column;
  color: #dd6b6b; // Color overridden by class color
  text-align: center;
  margin-top: 2rem;
  padding: 1.25rem;
  font-size: font-size('md');

  // There is a small window of time where the chart is still getting created and the error message is still visible.
  // This animation is to make the error message fade in after the chart is fully loaded.
  @include fadeInAnimation();

  button {
    display: block;
    margin-top: 2rem;
  }
}
