@use './styles/index' as *;

.container {
  display: flex;
  flex-direction: column;
  padding: 0.375rem 0.625rem 0.625rem;
  background-color: #1a1a1a;
  min-height: 100vh;
  position: relative;
}

.header {
  @extend %flex-center;
  margin: 0 1.25rem 0.375rem;
  position: relative;
  padding-bottom: 0.625rem;

  @media (max-width: $breakpoint-tablet) {
    margin-top: 0.625rem;
  }
}

.logo-and-title {
  @extend %flex-center;
  cursor: pointer;
}

.logo {
  width: 3.25rem;
  height: 3.25rem;
  margin-top: 0.25rem;
  margin-right: 1rem;
  @include rotateY360Animation('hover', '.logo-and-title:hover');
}

.title {
  @include yellowGreenGradient(false);
  font-size: font-size('lg');
  margin: 0;
  font-weight: bold;
}

.subtitle {
  color: #aaa;
  font-size: font-size('sm');
  margin: 0;
  font-weight: normal;
  font-style: italic;
}

.content {
  background-color: #1a1a1a;
  color: #ffffff;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0.5rem 1rem;
}

.footer {
  @extend %flex-center;
  padding: 1rem;
  white-space: pre-wrap;
  margin-top: auto;
  color: #999;
  font-size: font-size('xs');
  flex-wrap: wrap;
}

.credits {
  @media (max-width: $breakpoint-tablet) {
    max-width: 70vw;
  }

  @media (max-width: $breakpoint-mobile) {
    max-width: 60vw;
  }
}
