@use '../../../styles' as *;

.group {
  @extend %flex-center;
  flex-direction: column;
  flex: 0 1 $control-max-width; // Large screens: all controls in one row
  gap: 0.25rem;
  min-width: $control-min-width;
  width: 100%;

  label {
    font-size: font-size('xs');
    font-weight: 600;
    color: #aaa;
  }

  select {
    width: 100%;
    background-color: #333;
    color: #333;
    padding: 0.5rem 2rem 0.5rem 1rem;
    border: 1px solid #444;
    border-radius: 0.5rem;
    font-size: font-size('xs');
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    @include text-truncate;

    /* Default custom dropdown arrow (overridden by DropdownArrowIconUrl) */
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    background-position: right 0.75rem top 50%;
    background-size: 0.625rem auto;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;

    /* Remove default arrow in IE */
    &::-ms-expand {
      display: none;
    }

    /* Firefox specific styling */
    @-moz-document url-prefix() {
      padding-right: 1.5rem; /* Adjust padding for Firefox */
    }

    &:hover {
      background-color: $color-button-hover-bg;
      border-color: inherit;
    }

    &:focus {
      outline: none;
      border-color: inherit;
    }

    /* Ensure text is readable on iOS */
    @supports (-webkit-touch-callout: none) {
      font-size: 1rem; /* Prevent zoom on focus in iOS */
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      background-color: #252525;
      border-color: #444;

      &:hover {
        border-color: #444; // Prevent hover effect on disabled
      }
    }
  }

  // Medium+ screens: three controls per row
  @media (max-width: $breakpoint-tablet-plus) {
    flex: 1 1 calc(33.33% - 1.25rem);

    &:last-child:nth-child(3n + 1),
    &:last-child:nth-child(3n + 2) {
      margin-right: auto;
    }
  }

  // Small screens: two controls per row
  @media (max-width: $breakpoint-mobile) {
    flex: 1 1 calc(50% - 0.5rem);

    &:last-child:nth-child(odd) {
      margin-right: auto;
    }
  }
}
