$font-sizes: (
  'xxs': 0.75rem,
  'xs': 0.875rem,
  'sm': 1rem,
  'md': 1.25rem,
  'lg': 2rem,
);

%truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@function font-size($size) {
  @return map-get($font-sizes, $size);
}

@mixin text-truncate($max-width: 100%) {
  @extend %truncate;
  max-width: $max-width;
}
