@use '../../../styles' as *;

.container {
  h3 {
    @extend %flex-center;
    margin: 0 0 0.25rem;
    padding-bottom: 2rem;
    font-size: font-size('md');
  }
}

.option-title {
  font-weight: bold;
  color: #ccc;
}

.option-description {
  color: #bbb;
  font-size: font-size('xs');
  margin-top: 0.125rem;
  line-height: 1.25;

  &__highlight {
    font-weight: bold;
  }
}
