@use '../../../styles' as *;

.secondary-button {
  color: var(--color);
  border: 1px dashed var(--color);

  &:hover {
    color: var(--hover-color) !important;
    background: transparent !important;
    border-color: var(--hover-color) !important;
  }
}
