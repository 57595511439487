@use '../../../styles' as *;

.container {
  @extend %flex-center;
  justify-content: flex-start;
  flex: 1;
  padding: 0.375rem;
  background-color: #333;
  border: 1px dashed #444;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 6.25rem;
  max-width: 25rem;
  white-space: nowrap;

  &:hover {
    background-color: $color-button-hover-bg;
    border-style: solid;
  }

  &--active {
    background-color: $color-button-active-bg;
    border-width: 1px;
    border-style: solid;
  }

  // Medium+ screens: four buttons per row
  @media (max-width: $breakpoint-tablet-plus) {
    flex: 1 1 calc(25% - 0.75rem);

    &:last-child:nth-child(odd) {
      max-width: 100%; // This is why we need flex: 1 1 calc...
    }
  }

  // Small screens: three buttons per row
  @media (max-width: $breakpoint-mobile) {
    flex: 1 1 calc(33.33% - 0.75rem);
  }
}

.subclass-icons {
  img {
    width: 1rem;
    vertical-align: middle;
    object-fit: cover;
    margin-right: 0.125rem;
  }
  margin-right: 0.375rem;
}

.subclass-type {
  font-weight: 600;
  font-size: font-size('sm');
  line-height: 1.5rem;
}
