@use 'colors' as colors;

@mixin superFancyBorder($padding-vertical, $padding-horizontal) {
  $border-width: 0.0625rem;

  // Fallback
  @include default_border();

  @supports (mask-composite: exclude) or (-webkit-mask-composite: destination-out) {
    border: none;
    position: relative;
    padding: $padding-vertical + $border-width $padding-horizontal + $border-width;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid transparent;
      border-radius: inherit;
      background: linear-gradient(45deg, colors.$yellow-border, colors.$green-border) border-box;
      -webkit-mask:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
      mask:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
      pointer-events: none;
    }
  }
}

@mixin default_border($type: solid) {
  border-radius: 0.25rem;
  border: 1px $type #333;
}
