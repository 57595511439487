@use '../../styles' as *;

$transition-duration: 0.5s;

.container {
  @extend %flex-center;
  margin-left: auto;
  margin-right: 0.375rem;

  &__hover-text {
    @extend %flex-center;
    transform: translateX(1.25rem);
    opacity: 0;
    transition: all $transition-duration ease;
    padding-right: 0.75rem;
    white-space: nowrap;
    gap: 0.25rem;
    gap: 0.25rem;

    .gradient-link {
      padding-bottom: 2px;
    }
  }

  &__icon {
    width: 1rem;
    height: 1rem;
    transition: all $transition-duration ease;
    padding: 0.25rem;
    border: 2px solid transparent;
    border-radius: 50%;
    transform-origin: center;
    @include yellowGreenGradientIcon(false);

    @media (max-width: $breakpoint-tablet) {
      position: absolute;
      right: 1.625rem;
      bottom: 1.625rem;
      cursor: pointer;
    }
  }

  &:hover {
    .container__hover-text {
      transform: translateX(0);
      opacity: 1;
    }

    .container__icon {
      @include yellowGreenGradientIcon(true);
    }
  }
}
