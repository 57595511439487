@use '../../../styles' as *;

.primary-button {
  color: var(--color);

  &:hover {
    color: #ddd;
    background: var(--hover-bg-color) !important;
  }
}
