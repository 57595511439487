@use '../../../styles' as *;

.container {
  @extend %flex-center;
  color: #ff6b6b;
  background-color: #252525;
  text-align: center;
  padding: 0.5rem 0;
  flex-direction: column;
  position: relative;
}

.close-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: none;
  border: 2px solid #bf4b4b;
  border-radius: 1.625rem;
  color: #ff6b6b;
  font-size: font-size('md');
  cursor: pointer;
  padding: 0.125rem 0.375rem;
  line-height: 1;
  opacity: 0.6;
  transition: opacity 0.2s ease;

  &:active,
  &:hover {
    opacity: 1;
  }
}

.rotate-icon {
  font-size: font-size('lg');
  display: inline-block;
  transform: rotate(-90deg);
  margin-bottom: 0.5rem;
  @include rotateZ360Animation(true);
}
