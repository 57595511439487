@use './styles/index' as *;

.container {
  display: flex;
  flex-direction: column;
  padding: 0.375rem 0.625rem 0.625rem;
  background-color: #1a1a1a;
  color: #bbb;
  min-height: 100vh;
  position: relative;
  font-size: font-size('sm');
}

.header {
  @extend %flex-center;
  justify-content: flex-start;
  margin: 0.25rem 1.25rem 0.375rem;
  position: relative;
  padding-bottom: 0.625rem;

  @media (max-width: $breakpoint-tablet) {
    margin-top: 0.625rem;
  }
}

.logo-and-title {
  @extend %flex-center;
  cursor: pointer;
}

.logo {
  width: 3.25rem;
  height: 3.25rem;
  margin-top: 0.25rem;
  margin-right: 1rem;
  @include rotateY360Animation('hover', '.logo-and-title:hover');
}

.title {
  @include yellowGreenGradient(false);
  font-size: font-size('lg');
  margin: 0;
  font-weight: bold;
}

.subtitle {
  color: #aaa;
  font-size: font-size('sm');
  margin: 0;
  font-weight: normal;
  font-style: italic;
}

.content {
  display: flex;
  gap: 2rem;
  padding: 0.5rem 1rem;

  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;
  }
}

.loading {
  font-size: font-size('md');
  color: #aaa;
  height: 30vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error {
  font-size: font-size('md');
  color: #f55;
  height: 30vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-panel {
  flex: 1;
  min-width: 500px;
  background-color: $color-component-bg;
  padding: 1rem;
  border-radius: 4px;

  @media (max-width: $breakpoint-mobile) {
    min-width: auto;
    width: 100%;
    flex: 1 0 100%;
  }
}

.right-panel {
  flex: 2;
  background-color: $color-component-bg;
  padding: 1rem;
  border-radius: 4px;

  @media (max-width: $breakpoint-mobile) {
    flex: 1 0 100%;
    width: 100%;
    margin-top: 1rem;
  }
}

.left-panel,
.right-panel {
  h3 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    color: #ddd;
    font-weight: bold;
    font-size: font-size('md');
    border-bottom: 1px solid #444;
    padding-bottom: 0.5rem;
  }
}

.input-container {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  input {
    flex: 1;
    min-width: 20rem;
    padding: 0.5rem;
    border: 1px solid #888;
    border-radius: 0.25rem;
    background-color: $color-base-bg;
    color: #ffffff;
  }
}

.filters {
  margin-top: 1rem;
}

.filter-group {
  margin: 2rem 0;

  h4 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-size: font-size('sm');
    color: #ddd;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #444;
  }
}

.check-boxes {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;

  label {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    width: calc(33.33% - 0.5rem);
    min-width: 100px;
    margin-bottom: 0.5rem;
  }

  &.expansion-check-boxes {
    label {
      @media (max-width: $breakpoint-mobile) {
        width: calc(50% - 0.5rem);
        min-width: 0;
      }
    }
  }

  &.rarity-check-boxes {
    label {
      width: auto;
      min-width: 20px;
      margin-right: 1.5rem;
      flex: 0 0 auto;

      @media (max-width: $breakpoint-mobile) {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        width: calc(50% - 0.5rem);
        min-width: 0;
        margin-right: 0;
      }
    }
  }
}

.buttons {
  display: flex;
  gap: 1rem;
  margin-top: 3rem;

  button {
    padding: 0.5rem 1rem;
    border: 1px solid #888;
    border-radius: 0.25rem;
    background-color: $color-base-bg;
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #333;
      border-color: #aaa;
    }
  }
}

.last-updated {
  font-size: font-size('xs');
  color: #888;
  margin: 1rem 0 0 0.125rem;
}

.results-title {
  border-bottom: 1px solid #444;

  h3 {
    border-bottom: none;
    margin-bottom: 0;
    display: inline-block;
  }

  span {
    font-size: font-size('sm');
    color: #888;
    margin: 0 0.5rem;
  }
}

.results-container {
  margin-top: 1rem;
}

.results-info {
  font-size: font-size('sm');
  color: #aaa;
  margin: 0.5rem 0;
}

.results-cards {
  display: flex;
  flex-direction: column;
  font-size: font-size('xs');
  color: #eee;
  gap: 0.125rem;

  &__banner {
    padding: 0.25rem 0.5rem;
    border-top: 1px solid $color-component-bg;
    border-bottom: 1px solid $color-component-bg;

    &--1 {
      @extend .results-cards__banner;
      background: linear-gradient(to right, #003710, $color-component-bg);
    }

    &--2 {
      @extend .results-cards__banner;
      background: linear-gradient(to right, #001d45, $color-component-bg);
    }

    &--3 {
      @extend .results-cards__banner;
      background: linear-gradient(to right, #4f0000, $color-component-bg);
    }

    &--4 {
      @extend .results-cards__banner;
      background: linear-gradient(to right, #370054, $color-component-bg);
    }

    &--5 {
      @extend .results-cards__banner;
      background: linear-gradient(to right, #3a1300, $color-component-bg);
    }

    &--6 {
      @extend .results-cards__banner;
      background: linear-gradient(to right, #00434c, $color-component-bg);
    }

    &--8 {
      @extend .results-cards__banner;
      background: linear-gradient(to right, #474500, $color-component-bg);
    }

    &--9 {
      @extend .results-cards__banner;
      background: linear-gradient(to right, #000000, $color-component-bg);
    }

    &--10 {
      @extend .results-cards__banner;
      background: linear-gradient(to right, #471a00, $color-component-bg);
    }
  }
}

.result-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px dashed #111;
  margin: -0.3125rem 0;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.01);
  }

  &--struck {
    text-decoration: line-through;
    opacity: 0.5;
    background-color: rgba(0, 0, 0, 0.5) !important;
    // Wierd visual effect work-around...
    margin-left: -0.5rem;
    padding-left: 0.5rem;
  }

  &__rarity {
    margin-right: 0.75rem;
    min-width: 1.5rem;
  }

  &__name {
    display: inline-block;
    width: 10rem;
    @include text-truncate;
  }

  &__keywords {
    display: inline-block;
    color: #aaa;
    width: 8rem;
    @include text-truncate;
    margin-left: auto;
    text-align: right;
    font-style: italic;
    font-size: font-size('xxs');
  }

  &__expansion {
    color: #999;
    width: 8rem;
    margin-left: auto;
    margin-right: 0.5rem;
    text-align: right;
  }
}

.rarity-icon {
  color: #90561c;

  &--0 {
    @extend .rarity-icon;
    width: 14px;
    height: 14px;
    margin-top: 0.125rem;
    margin-left: 0.125rem;
  }

  &--1 {
    @extend .rarity-icon;
    width: 22px;
    height: 22px;
    margin-top: 0.125rem;
    margin-left: -0.125rem;
    color: rgb(44, 201, 97);
  }

  &--2 {
    @extend .rarity-icon;
    width: 20px;
    height: 18px;
    margin-top: 0.25rem;
    color: rgb(27, 119, 238);
  }

  &--3 {
    @extend .rarity-icon;
    width: 20px;
    height: 22px;
    color: rgb(230, 224, 61);
  }
}
