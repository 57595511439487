/**
 * Rotates element around the Y axis
 * @param {string} $trigger - 'hover' = hover effect, 'auto' = rotate on render
 * @param {string} $parent - selector for hover trigger
 */
@mixin rotateY360Animation($trigger, $parent: false) {
  transition: transform 1s ease;
  transform-style: preserve-3d;
  perspective: 1000px;

  @if $trigger == 'hover' {
    @if $parent {
      #{$parent} & {
        transform: rotateY(360deg);
      }
    } @else {
      &:hover {
        transform: rotateY(360deg);
      }
    }
  } @else {
    animation: rotateY360 1.2s ease;
  }
}

@keyframes rotateY360 {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

/**
 * Rotates element around the Z axis
 */
@mixin rotateZ360Animation($infinite) {
  @if $infinite {
    animation: rotateZ360 3s linear infinite;
  } @else {
    animation: rotateZ360 3s linear;
  }
}

@keyframes rotateZ360 {
  from {
    transform: rotateZ(-90deg);
  }
  to {
    transform: rotateZ(270deg);
  }
}

@keyframes rotateZ360AndScale {
  0% {
    transform: rotateZ(0deg) scale(0.75);
  }
  50% {
    transform: rotateZ(360deg) scale(1);
  }
  100% {
    transform: rotateZ(720deg) scale(0.75);
  }
}

@mixin pulseAnimation {
  animation: pulse 1.4s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    opacity: 0.7;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0.7;
  }
}

@mixin pulsingDotsAnimation {
  & > span {
    display: inline-block;
    animation: dotPulse 1.2s infinite;

    &:nth-child(2) {
      animation-delay: 0.4s;
    }

    &:nth-child(3) {
      animation-delay: 0.8s;
    }
  }
}

@keyframes dotPulse {
  0% {
    opacity: 0.2;
    transform: scale(0.5);
  }
  30% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0.2;
    transform: scale(0.5);
  }
}

@mixin peekUpAnimation {
  opacity: 0; // Start hidden. `Forwards` will make it visible.
  animation: peekUp 0.6s ease-out 0.1s forwards;
}

@keyframes peekUp {
  from {
    transform: translateY(50%) scale(0.5);
    opacity: 0;
  }
  to {
    transform: translateY(0) scale(1);
    opacity: 0.9;
  }
}

@mixin fadeInAnimation {
  opacity: 0; // Start hidden. `Forwards` will make it visible.
  animation: fadeIn 1s ease-out 0.2s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
