$yellow-base: #cfaa26;
$yellow-hover: #e4ca5f;
$yellow-border: #9d8739;

$green-base: #249624;
$green-hover: #2bc02b;
$green-border: #176616;

$color-base-bg: #1a1a1a;

$color-component-bg: #1f1f1f;
$color-list-hover-bg: #2c2c2c;
$color-button-hover-bg: #3f3f3f;
$color-button-active-bg: #494949;

$color-chart-footer: #888;
