@use '../../styles' as *;

$transition-duration: 0.5s;

.container {
  @extend %flex-center;
  margin-left: auto;
  margin-right: 0.375rem;
  margin-bottom: -1.5rem;
  font-size: font-size('xs');
  color: #999;

  &__hover-text {
    @extend %flex-center;
    flex-direction: column;
    transform: translateX(1.25rem);
    margin-top: 0.375rem;
    opacity: 0;
    transition: all $transition-duration ease;
    padding-right: 0.75rem;
    gap: 0.25rem;
    max-width: 34.375rem;
    white-space: normal;
    line-height: 1.4;

    p {
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }

  &__question-icon {
    width: 1rem;
    height: 1rem;
    transition: all $transition-duration ease;
    padding: 0.25rem;
    border: 2px solid transparent;
    border-radius: 50%;
    transform-origin: center;
    @include yellowGreenGradientIcon(false);

    @media (max-width: $breakpoint-tablet) {
      position: absolute;
      right: 0.375rem;
      top: 1rem;
      cursor: pointer;
    }
  }

  &:hover {
    .container__hover-text {
      transform: translateX(0);
      opacity: 1;
    }

    .container__question-icon {
      @include yellowGreenGradientIcon(true);
    }
  }
}
