@use '../../../styles' as *;

.container {
  @extend %flex-center;
  justify-content: space-between;
  text-align: center;
  color: $color-chart-footer;
  font-size: font-size('xs');
  padding: 0.75rem 0.5rem 0.5rem;
  gap: 0.5rem;
  border-top: 1px dashed #333;
  margin-top: 0.5rem;
  height: 1.5rem;

  @media (max-width: $breakpoint-mobile) {
    justify-content: center;
  }
}

.energy {
  &--left,
  &--right {
    display: block;
  }

  &--loading {
    margin-bottom: 0.25rem;
  }

  @media (max-width: $breakpoint-mobile) {
    &--loading {
      display: none;
    }
    &--right {
      display: none;
    }
  }
}

.content {
  @extend %flex-center;
  gap: 0.5rem;

  &--loading-in-background {
    margin-bottom: 0.25rem;
  }
}

.refresh-button {
  color: $color-chart-footer;
  min-width: 5.5rem !important;
  padding: 0.4375rem 0.5rem !important;
  margin-left: 0.5rem;

  &:hover {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}
