@use '../../../styles' as *;

$max-width: 32rem;
$background-color: #1a1a1a;

.overlay {
  @extend %flex-center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
}

.content {
  position: relative;
  background: $background-color;
  padding: 1.25rem;
  width: 90%;
  max-width: $max-width;
  margin: auto;
  @include default-border;

  &--without-class-border {
    @include superFancyBorder(1.25rem, 1.25rem);
  }

  h3 {
    @extend %flex-center;
    justify-content: flex-start;
    color: #ccc;
    margin-top: 5px;
    gap: 0.5rem;
    margin-block-end: 0;
  }

  p {
    color: #aaa;
    line-height: 1.4;
  }

  @media (max-width: $breakpoint-mobile) {
    margin: auto 1.5rem;
  }

  // Make the modal scrollable on mobile landscape
  @media (orientation: landscape) and (max-height: 600px) {
    max-height: 80vh;
    overflow-y: auto;
  }
}
