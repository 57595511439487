@use 'colors' as colors;

@mixin yellowGreenGradient($hover, $default: #fff) {
  // Fallback
  color: $default;

  @supports (-webkit-background-clip: text) or (background-clip: text) {
    @include yellowGreenGradientBackground($hover);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
}

@mixin yellowGreenGradientBackground($hover: false) {
  background: linear-gradient(
    45deg,
    if($hover, colors.$yellow-hover, colors.$yellow-base),
    if($hover, colors.$green-hover, colors.$green-base)
  );
}

@mixin yellowGreenGradientIcon($hover) {
  @if $hover {
    transform: scale(1.25);
    opacity: 0.8;
    background:
      linear-gradient(black, black) padding-box,
      linear-gradient(to right, colors.$yellow-hover, colors.$green-hover) border-box;
  } @else {
    opacity: 0.6;
    background:
      linear-gradient(black, black) padding-box,
      linear-gradient(to right, colors.$yellow-base, colors.$green-base) border-box;
  }
}
