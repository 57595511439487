@use '../../styles' as *;

.layout {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  overflow: visible;

  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
    padding: 0 0.625rem 0 0;
  }
}

.container {
  flex: 1;
  // To make sure that the chart doesn't push the legend out of the screen
  max-width: calc(100% - ($chart-legend-width + 2rem));
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 0;
  background-color: #1a1a1a;
  padding: 0.25rem;
  @include default_border();

  @media (max-width: $breakpoint-tablet) {
    max-width: 100%; // NB: Necessary to remove the `100% - legend-width` constraint
    width: 100%;
  }
}

.chart-container {
  height: $chart-container-height;
  overflow: auto;

  &--mobilePortraitLoading {
    height: 16.25rem;
  }

  &__chart {
    height: 100%;

    transition:
      // Smoother transition when changing zoom level
      width 0.5s,
      // Fade out when updating
      opacity 0.3s ease-in-out;

    &--updating {
      opacity: 0;
    }

    canvas {
      min-height: 100%;
    }
  }

  @media (max-width: $breakpoint-tablet) {
    width: 100%;
  }
}
