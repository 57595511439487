@use '../../styles' as *;

.link {
  @include yellowGreenGradient(false);
  text-decoration: none;
  transition: opacity 0.4s ease;
  font-weight: 600;
  position: relative;

  &:hover {
    @include yellowGreenGradient(true);

    &::after {
      transform: scaleX(1);
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 1px;
    @include yellowGreenGradientBackground(1);
    transform: scaleX(0);
    transition: transform 0.5s ease;
    transform-origin: left;
  }
}
