@use '../../../styles' as *;

// Increased specificity to override the button styles
.gradient-button.gradient-button {
  font-weight: bold;
  min-width: 5rem;

  &--colored {
    @include yellowGreenGradient(false, #ccc);
  }

  &:hover {
    @include yellowGreenGradient(true, #fff);
    @include superFancyBorder($padding-button-vertical, $padding-button-horizontal);
  }
}
