// Chart
$chart-container-height: 31.25rem;
$chart-legend-height: 33.375rem;
$chart-legend-width: 13.75rem;

// Controls
$control-min-width: 8rem;
$control-max-width: 25rem;

// Component separators
$padding-component: 0.875rem;

// Buttons
$padding-button-horizontal: 0.625rem;
$padding-button-vertical: 0.5rem;
