@use '../../styles' as *;

.energy {
  width: 1.25rem;
  height: 1.25rem;
  padding-top: 2px;

  @media (max-width: $breakpoint-mobile) {
    width: 1rem;
    height: 1rem;
  }
}
