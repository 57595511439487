@use '../../../styles' as *;

h3.title {
  margin-top: 0.5rem;
  align-items: end;
}

.icon {
  margin-top: -1rem;
  margin-left: -0.375rem;
  width: 4rem;
  height: auto;
  object-fit: contain;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.8));
  @include peekUpAnimation;
}

.player {
  color: #bbb;
  font-weight: bold;
}

.subclass {
  font-weight: normal;
}

.anonymous {
  font-weight: bold;
  font-style: italic;
}
